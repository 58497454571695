<template>
    <main>
        <Navbar />
        <div class="main">
           <div class="container">
               <div class="btn-group w-100" role="group">
                   <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                        <i class="far fa-newspaper mr-2" v-show="platform == 'desktop'"></i>
                        <span>Criar</span>
                   </button>
                   <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                        <i class="fad fa-badge-dollar mr-2" v-show="platform == 'desktop'"></i>
                        <span>Desafios</span>
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="cat == 2 && events.length > 0">{{events.length}}</span>
                   </button>
               </div><!-- /btn-group -->
                <div v-show="loading == true" class="alert alert-warning mt-3" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error.length > 0" class="alert alert-danger mt-3" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                    <hr />
                    <div class="row ml-0 mt-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="status">
                                <option value="0" selected>Aberto</option>
                                <option value="1">Finalizado</option>
                            </select>
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="challenges"><i class="fas fa-search mr-2"></i>Buscar</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                </div><!-- /alert-danger -->
               <div class="mt-3 mb-3">
                   <template v-if="cat == 1">
                        <form @submit.prevent="newChallenge">
                            <div class="form-group">
                                <label for="title" class="text-light">Título</label>
                                <input type="text" class="form-control" v-model="challenge.title" @keypress="updateOptionValue($event)" id="title" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="description" class="text-light">Descrição</label>
                                <input type="text" class="form-control" v-model="challenge.description" @keypress="updateOptionValue($event)" id="description" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="datetime" class="text-light">Horário de encerramento</label>
                                <input type="datetime-local" class="form-control" v-model="challenge.datetime" id="datetime" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="options" class="text-light">Informe o número de opções</label>
                                <template v-if="platform == 'desktop'">
                                    <div class="row">
                                        <div class="col-8">
                                            <input type="text" class="form-control" v-model="set_options" @keypress="IsNumber($event)" id="options" autocomplete="off" spellcheck="false" required>
                                        </div>
                                        <div class="col-4">
                                            <div class="row">
                                                <div class="col">
                                                    <a class="btn btn-success btn-block text-light" @click="increment">
                                                        <i class="fas fa-plus mr-2"></i>1 opção
                                                    </a>
                                                </div>
                                                <div class="col">
                                                    <a class="btn btn-danger btn-block text-light" @click="disagrees">
                                                        <i class="fas fa-minus mr-2"></i>1 opção
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <input type="text" class="form-control" v-model="set_options" @keypress="IsNumber($event)" id="options" autocomplete="off" spellcheck="false" required>
                                    <div class="row mt-3">
                                        <div class="col">
                                            <a class="btn btn-success btn-block text-light" @click="increment">
                                                <i class="fas fa-plus mr-2"></i>1 opção
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a class="btn btn-danger btn-block text-light" @click="disagrees">
                                                <i class="fas fa-minus mr-2"></i>1 opção
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="form-group" v-for="(item, index) in challenge.options" :key="index">
                                <div class="row">
                                    <div class="col text-truncate">
                                        <label :for="`option-${index}`" class="text-light">Nome da opção ({{item.id}})</label>
                                        <input type="text" class="form-control" v-model="challenge.options[index].option" @keypress="updateOptionValue($event)" @paste="noPaste($event)" :id="`option-${index}`" autocomplete="off" spellcheck="false" required>
                                    </div>
                                    <div class="col text-truncate">
                                        <label :for="`option-quotation-${index}`" class="text-light">Cotação da opção ({{item.id}})</label>
                                        <input type="text" class="form-control" v-model="challenge.options[index].odd" @keypress="isNumberKey($event)" @paste="noPaste($event)" :id="`option-quotation-${index}`" autocomplete="off" spellcheck="false">
                                    </div>
                                </div>
                            </div>
                            <button v-if="set_options > 0" type="submit" class="btn btn-primary mb-3" :class="{'btn-block': platform == 'mobile', 'float-right': platform == 'desktop'}" :disabled="loading == true">
                                <i class="fas fa-check-circle mr-2"></i>
                                <span>Criar desafio</span>
                            </button>
                        </form>
                   </template>
                   <template v-else-if="events.length > 0">
                        <div class="row ml-0 mt-3 mb-3 mr-0">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="status">
                                    <option value="0" selected>Aberto</option>
                                    <option value="1">Finalizado</option>
                                </select>
                            </div>
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <button class="btn btn-warning btn-block" @click="challenges"><i class="fas fa-search mr-2"></i>Buscar</button>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="card mb-3" v-for="(event, index) in events" :key="index">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-8 text-truncate">{{event.titulo}}</div>
                                    <div class="col-4 text-truncate text-right">{{event.horario | formatDate}}</div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 p-0 text-truncate">
                                        <div class="pl-3 pr-3 pt-2 pb-2 bg-secondary text-light rounded">{{event.descricao}}</div>
                                    </div>
                                </div><!-- /row -->
                                <div class="row mt-2" v-for="(item, i) in event.odds" :class="{'border-bottom pb-2': i < event.odds.length - 1}" :key="i">
                                    <div class="col text-truncate" :class="{'text-success': item.id == event.resultado}">{{item.option}}</div>
                                    <div class="col text-truncate"><i class="fas fa-dollar-sign mr-2"></i>{{item.odd}}</div>
                                </div><!-- /row -->
                                <div class="row mt-3" v-if="event.status == 0">
                                    <div class="col-12">
                                        <div class="w-100" :class="{'btn-group': platform == 'desktop', 'btn-group-vertical': platform == 'mobile'}" role="group">
                                            <button class="btn btn-primary" :class="{'mb-2 rounded-bottom': platform == 'mobile'}" @click="editChallenge(event._id)">
                                                <i class="fas fa-edit mr-2"></i>Editar
                                            </button>
                                            <button class="btn btn-dark" :class="{'mb-2 rounded-top rounded-bottom': platform == 'mobile'}" @click="locksAndUnlocks(event._id, event.bloqueado)">
                                                <i class="mr-2" :class="{'fas fa-lock': event.bloqueado == 2, 'fas fa-unlock': event.bloqueado == 1}"></i>{{event.bloqueado == 2 ? 'Bloquear' : 'Desbloquear'}}
                                            </button>
                                            <button class="btn btn-success" :class="{'mb-2 rounded-top rounded-bottom': platform == 'mobile'}" @click="finish(event._id)">
                                                <i class="fas fa-badge-check mr-2"></i>Finalizar
                                            </button>
                                        </div><!-- /btn-group -->
                                    </div><!-- /col -->
                                </div><!-- /row -->
                            </div><!-- /card-body -->
                        </div><!-- /card -->
                   </template>
               </div><!-- /mt-3 mb-3 -->
           </div><!-- /container -->
        </div><!-- /main -->
         <modal name="edit-odds" :width="platform == 'desktop' ? 600 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" :classes="['modal-dark','modal-edit-dark']" @before-open="beforeOpen">
            <template v-if="Object.keys(modal).length">
                <header class="modal-edit-header">
                    <div class="row">
                        <div class="col-8 text-light text-uppercase mt-1">Editar opções</div>
                        <div class="col-4 text-right">
                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                <button class="btn btn-sm btn-success" @click="saveOdds">
                                    <template v-if="platform == 'desktop'">
                                        <i class="fas fa-save mr-2"></i>Salvar
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-save"></i>
                                    </template>
                                </button>
                                <div class="modal-edit-close ml-3" role="button" @click="$modal.hide('edit-odds')">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="modal-edit-scroll-area">
                    <div class="container">
                        <div class="row" v-for="(item, index) in modal.odds" :key="index">
                            <div class="col-8">
                                <input type="text" class="form-control" v-model="modal.odds[index].option" @keypress="updateOptionValue($event)" @paste="noPaste($event)" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="col-4">
                                <div class="d-flex flex-column">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">$</div>
                                    </div>
                                        <input type="number" class="form-control" v-model="modal.odds[index].odd" @keypress="isNumberKey($event)" @paste="noPaste($event)" autocomplete="off" spellcheck="false">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import Vue from 'vue'
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import VModal from 'vue-js-modal'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(VModal)

export default {
    data() {
        return {
            cat: 1,
            status: 0,
            error: '',
            modal: {},
            events: [],
            set_options: '',
            challenge: {
                title: '',
                options: [],
                datetime: '',
                description: '',
            },
            loading: false,
            isLoading: false,
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        newChallenge() {

            const self = this;

            if (self.challenge.title.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o título do desafio!',
                    'warning'
                );
            } else if (self.challenge.description.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a descrição do desafio!',
                    'warning'
                );
            } else if (self.challenge.datetime == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe horário de encerramento do desafio!',
                    'warning'
                );
            } else if (self.challenge.options.length == 0) {
                Swal.fire(
                    'Atenção!',
                    'Adicione pelo menos uma opção no seu desafio!',
                    'warning'
                );
            } else if (self.challenge.options.some((obj) => obj.odd.toString().trim() == '' || parseFloat( obj.odd) <= 1 || obj.option.toString().trim() == '')) {
                Swal.fire(
                    'Atenção!',
                    'Preencha todos os campos de todas as opções criadas. Cotação menor ou igual a 1 é considerada inválida!',
                    'warning'
                );
            }
            else 
            {
                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo criar esse desafio?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.post('dashboard/challenge', {
                            challenge: self.challenge
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.clearChallenge();

                                    Swal.fire(
                                        'Parabéns!',
                                        'Seu desafio foi criado com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    )
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        clearChallenge() {
            this.set_options = '';
            this.challenge.options = [];
            this.challenge.description = '';
        },
        challenges() {

            const self = this;

            self.error = '';
            self.events = [];
            self.loading = true;

            api.get(`dashboard/challenge/${self.status}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events;
                    break;
                    case 'nothing_found':
                        self.status = 0;
                        self.error = 'Não encontramos nenhum desafio!';
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        async finish(id) {

            const self = this;
            const index = self.events.findIndex((obj => obj._id == id));

            try {

                const event = self.events[index];

                const { value: formValues } = await Swal.fire({
                    title: 'Finalizar desafio',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Finalizar',
                    html: `
                        <label class="d-block text-left mb-0">Quem venceu o desafio?</label>
                        <select id="swal-input1" class="swal2-input w-100">
                            ${event.odds.map((item) => {
                                return `<option value="${item.id}">${item.option}</option>`
                            })}
                        </select>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            result: document.getElementById('swal-input1').value,
                        }
                    },
                });

                if (formValues) {

                    Swal.fire({
                        title: 'Confirmação',
                        html: `Você deseja mesmo finalizar o desafio <p class="mb-0"><b>"${event.titulo} - ${event.descricao}" ?</b></p><hr /><p class="m-0"><b class="text-success">${event.odds[formValues.result - 1].option}</b></p>`,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Sim'
                    }).then((result) => {

                        if (result.value) {

                            self.isLoading = true;

                            api.put(`dashboard/challenge/${id}/${formValues.result}`).then((response) => {
                                switch(response.data.result) {
                                    case 'success':
                                        Swal.fire(
                                            'Parabéns!',
                                            'As alterações foram salvas com sucesso!',
                                            'success'
                                        );
                                    break;
                                    default:
                                        Swal.fire(
                                            'Atenção!',
                                            response.data.message,
                                            'warning'
                                        );
                                }
                            }).catch((error) => {
                                try {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[error.status]['message'],
                                        'error'
                                    );
                                } catch(e) {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[408]['message'],
                                        'error'
                                    );
                                }
                            }).finally(() => {
                                self.isLoading = false;
                            });
                        }
                    });
                }
            } 
            catch(e) 
            {
                //
            }
        },
        editOdds(id) {

            this.modal = this.events.find((item) => item._id == id);

            if (this.modal instanceof Object) {
                this.$modal.show('edit-odds');
            }
        },
        saveOdds() {

            const self = this;

            self.$modal.hide('edit-odds');

            if (self.modal.odds.some((item) => item.odd == '')) {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor da cotação!',
                    'warning'
                );
            }
            else
            {
                self.isLoading = true;

                api.post('dashboard/challenge/edit/odds', {
                    id: self.modal._id,
                    odds: self.modal.odds
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        beforeOpen() {
            Swal.close();
        },
        async editChallenge(id) {

            const self = this;
            const index = self.events.findIndex((obj => obj._id == id));

            try {

                const event = self.events[index];

                const { value: formValues } = await Swal.fire({
                    title: 'Editar desafio',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <label class="d-block text-left mb-0">Título</label>
                        <input id="swal-input1" class="swal2-input" value="${event.titulo}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Descrição</label>
                        <input id="swal-input2" class="swal2-input" value="${event.descricao}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Horário de encerramento</label>
                        <input id="swal-input3" class="swal2-input" value="${moment(event.horario).format('YYYY-MM-DD[T]HH:mm')}" type="datetime-local" autocomplete="off" spellcheck="false">

                        <button id="edit-quotations" class="btn btn-lg btn-secondary btn-block">
                            <i class="fas fa-edit mr-2"></i>Editar opções
                        </button>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            titulo: document.getElementById('swal-input1').value,
                            horario: document.getElementById('swal-input3').value,
                            descricao: document.getElementById('swal-input2').value,
                        }
                    },
                    onOpen: () => {
                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
                        });

                        document.getElementById("edit-quotations").addEventListener("click", () => {
                            return self.editOdds(id);
                        });
                    }
                }); 
                
                if (formValues) {
                    
                    if (formValues.titulo.trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o título do desafio!',
                            'warning'
                        );
                    } else if (formValues.descricao.trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe a descrição do desafio!',
                            'warning'
                        );
                    } else if (formValues.horario.trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o horário do desafio!',
                            'warning'
                        );
                    } else {

                        self.isLoading = true;

                        api.post('dashboard/challenge/edit', {
                            id: id,
                            titulo: formValues.titulo,
                            horario: formValues.horario,
                            descricao: formValues.descricao
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    event.titulo = formValues.titulo;
                                    event.horario = formValues.horario;
                                    event.descricao = formValues.descricao;

                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                }
            }
            catch(e) 
            {
                //
            }
        },
        locksAndUnlocks(id, value) {

            const self = this;
            const type = value == 2 ? 1 : 2;
            const label = type == 1 ? 'Bloquear' : 'Desbloquear';
            const event = self.events.find((obj => obj._id == id));

            Swal.fire({
				title: `${label} evento`,
				html: `Você deseja mesmo ${label.toLowerCase()} <p class="mb-0"><b>"${event.titulo} - ${event.descricao}"</b> ?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {

                    Swal.fire({
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        text: 'Salvando alterações...',
                        onBeforeOpen: () => {
                            Swal.showLoading()  
                        }
                    });

                    api.put(`dashboard/lock-unlock-event/${id}/${type}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                event.bloqueado = type;

                                Swal.fire(
                                    'Parabéns!',
                                    `O evento foi ${type == 1 ? 'bloqueado' : 'debloqueado'} com sucesso!`,
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'warning'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'warning'
                            );
                        }
                    });
                }
            });
        },
        increment() {
            this.set_options++;
        },
        disagrees() {
            this.set_options--;
        },
        removeAccents(str) {

            let string = str;
            let map = {
                a : /[\xE0-\xE6]/g,
                A : /[\xC0-\xC6]/g,
                e : /[\xE8-\xEB]/g,
                E : /[\xC8-\xCB]/g,
                i : /[\xEC-\xEF]/g,
                I : /[\xCC-\xCF]/g,
                o : /[\xF2-\xF6]/g,
                O : /[\xD2-\xD6]/g,
                u : /[\xF9-\xFC]/g,
                U : /[\xD9-\xDC]/g,
                c : /\xE7/g,
                C : /\xC7/g,
                n : /\xF1/g,
                N : /\xD1/g,
            };

            for (let key in map) {
                let rex = map[key];
                string = string.replace(rex, key);
            }

            return string;
        },
        IsNumber(event) {
            if (!/\d/.test(event.key)) return event.preventDefault();
        },
        updateValue(event) {
           let value = event.target.value;

           value = value.replace(/\D/g,"");  
           value = value.replace(/[0-9]{12}/," ");  
           value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
           value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
           value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
        
           event.target.value = value.replace(',','.');
        },
        isNumberKey(event) {
            event = (event) ? event : window.event;
            let charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            } else {
                return true;
            }
        },
        noPaste(event) {
            return event.preventDefault();
        },
        updateOptionValue(event) {
           event.target.value = event.target.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY - HH:mm');
        }
    },
    watch: {
        set_options(value) {

            this.challenge.options = [];

            if (value < 0) {
                this.set_options = '';
                return;
            }

            if (value <= 100) {

                for (let i = 1; i <= value; i++) {

                    if (this.challenge.options.length < 100) {
                        this.challenge.options.push({
                            id: i,
                            odd: '',
                            option: ''
                        });
                    }
                }
            }
            else
            {
                Swal.fire(
                    'Atenção!',
                    'Você só pode adicionar 100 opções por desafio!',
                    'warning'
                );
            }
        },
        cat(value) {

            this.error = '';

            if (value == 2) {
                this.challenges();
            }
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.screen_w = window.screen.width;
            this.screen_h = window.screen.height;
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.btn .badge {
    top: 3px!important;
}
.modal-edit-scroll-area {
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden auto;
    height: calc(100% - 56px);
}
.modal-edit-scroll-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #4b4b4b;
    border-radius: 0;
}
.modal-edit-scroll-area::-webkit-scrollbar-thumb {
    background: #373737;
    border-radius: 0;
}
.modal-edit-close {
    color: #fff;
}
.modal-edit-close:hover {
    color: #ddd;
}
</style>